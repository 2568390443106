import { combineReducers } from "redux";
import { deleteCaseReducer, getSteps } from "../FilePreparation/Reducer";
import { getPatientDemographicsDetailsReducer } from "../patientDemographicsDetails/patientDemographicsDetailsByPatientID/Reducer";
import { getSharedPatientDetailsReducer } from "../sharedPatientTeamDetailsByPatientId/Reducer";
import {
  addCCReducer,
  deleteDocumentReducer,
  deletePatientReducer,
  getCaseReducer,
  getCCReducer,
  getDoctorReducer,
  getPatientReducer,
  getSharedPatientReducer,
  getQuestionReducer,
  getTaskReducer,
  getOwnerAppoinmentListReducer,
  getCaseAppoinmentListReducer,
  getOrganizationListReducer,
} from "./Reducer";
import { getHealthcampListReducer } from "../healthcampList/Reducer";
import { getHealthcampPatientReducer } from "../patientListOfHealthcamp/Reducer";
import { logOutReducer } from "../logOut/Reducer";
import { getPatientDataDetailsListReducer } from "../patientDataDetails/patientDataPointListByPatientID/Reducer";
import { getPatientDataPointListReducer } from "../patientDataPointList/patientDataPointListByPatientID/Reducer";
import { addPointDataDetailsReducer } from "../patientDataDetails/addPatientDetails/Reducer";
import { updatePatientDataDetailsReducer } from "../patientDataDetails/UpdatePatientDataPoint/Reducer";
import { simpleHistoryReducer } from "../SimpleHistory/Reducer";
import { addFavoritePatientReducer } from "../addFavoritePatient/Reducer";
import { extractObservationByPatientIdReducer } from "../extractObservationDetailsByPatientId/Reducer";
import { getDemographicsDetailsByPtIdReducer } from "../DemographicsDetails/GetAppointmentList/Reducer";
import { extractObservationByDetailsIdReducer } from "../extractObservationDetailsById/Reducer";
import { GetExtractObservationByPatientIdReducer } from "../getExtractObservationByPatientId/Reducer";
import { getPatientDetailsByIdReducer } from "../patientDetailsById/Reducer";
import { GetExtractObservationByDetailsIdReducer } from "../GetViewExtractObservation/Reducer";
import { addDemographicsReducer } from "../DemographicsDetails/AddDemographicsDetails/Reducer";
import { getRelationshipListReducer } from "../DemographicsDetails/RelationShipList/Reducer";
import { getBloodGroupListReducer } from "../DemographicsDetails/BloodGroupList/Reducer";
import { getGenderListReducer } from "../DemographicsDetails/GenderList/Reducer";
import { getMaritalListListReducer } from "../DemographicsDetails/MaritalStatusList/Reducer";
import { updateDemographicsDetailsByPatientIdReducer } from "../DemographicsDetails/UpdateDemographicsDetails/Reducer";
import { getSexualOrientationListReducer } from "../DemographicsDetails/SexualOrientationList/Reducer";
import { emailOtpgenerationReducer } from "../emailOtpGeneration/Reducer";
import { emailOtpSignupReducer } from "../emailOtpSignup/Reducer";
import { emailOtpAuthReducer } from "../emailOtpAuth/Reducer";
import { forgotPasswordReducer } from "../forgotPassword/Reducer";
import { requestBookAppointmentReducer } from "../requestAppointmentByPatient/Reducer";
import { getPaymentListByOwnerReducer } from "../payment/AppointmentPaymentList/Reducer";
import { checkPaymentStatusByIdReducer } from "../payment/CheckPaymentStatusById/Reducer";
import { getDoctorDetailsReducer } from "../doctorDetailsById/Reducer";
import { needHelpReducer } from "../needHelp/addNeedHelp/Reducer";
import { dropdownReducer } from "../needHelp/patientList/Reducer";
import { getUserDetailsListReducer } from "../getUserDetails/Reducer";
import { resetPasswordReducer } from "../resetPassword/Reducer";
import { setEmailPhoneReducer } from "../setEmailOrPhone/Reducer";
import { addPatientImportConfigReducer } from "../addPatientImportConfig/Reducer";
import { getPatientImportConfigListReducer } from "../getPatientImportConfigList/Reducer";
import { editPatientImportConfigReducer } from "../editPatientImportConfig/Reducer";
import { imageUploadReducer } from "../ImageUpload/Reducer";
import { getInsuranceDetailsReducer } from "../getInsuranceDetailsByPatient/Reducer";
import { addPatientInsuranceReducer } from "../addPatientInsurance/Reducer";
import { categoryListReducer } from "../findDoctor/category/Reducer";
import { speciaListReducer } from "../findDoctor/specialist/Reducer";
import { doctorListReducer } from "../findDoctor/searchDoctor/Reducer";
import { getLanguageListReducer } from "../DemographicsDetails/LanguageList/Reducer";
import { getAppointmentReqListReducer } from "../Appointment/GetAppointmentList/Reducer";
import { createAppointmentReqReducer } from "../Appointment/RequestAppointment/Reducer";
import { getAppointmentPaymentListReducer } from "../appointmentPayment/AppointmentPaymentList/Reducer";
import { existingAppointmentListReducer } from "../appointmentPayment/ExistingAppointmentList/Reducer";
import { getSelectedDoctorTeamTypeReducer } from "../SelectDoctorForAppointment/GetSelectedDoctorTeamType/Reducer";
import { getAppointmentSelectedDoctorListReducer } from "../SelectDoctorForAppointment/GetAppointmentList/Reducer";
import { deleteDoctorReducer } from "../SelectDoctorForAppointment/DeleteDoctor/Reducer";
import { getDoctorCategoryListReducer } from "../SelectDoctorForAppointment/GetDoctorCategoryList/Reducer";
import { patientDropdownListReducer } from "../patientListDropdown/Reducer";
import { getInstantMeetingReducer } from "../FilePreparation/ZoomMetting/Reducer";
import { getQuestionListForPatientReducer } from "../FilePreparation/getQuestionListForPatient/Reducer";
import {
  addPatientCaseReducer,
  consultationTypeListReducer,
  getOpinionTypeListReducer,
  getPatientCaseDetailsReducer,
  responseTimeReducer,
  secondOpinionListReducer,
} from "../ConsultationOption/Reducer";
import { getPatientTeamReducer } from "../PatientTeam/GetPatientTeam/Reducer";
import { addPatientTeamReducer } from "../PatientTeam/AddPatientTeam/Reducer";
import { addHealthcampPatientReducer } from "../addHealthcampPatient/Reducer";
import { getHealthRecordReducer } from "../Patient/DemographicsDetails/Reducer";
import {
  appointmentConfirmReducer,
  appointmentListReducer,
  appointmentPaymentListReducer,
  appointmentPaymentStatusReducer,
} from "../Patient/AppointmentPayment/Reducer";
import {
  addrequestAppointment,
  requestAppointmentDetails,
} from "../Patient/RequestAppointment/Reducer";
import { deleteOpinion, getOpinion } from "../Patient/FinalOpinion/Reducer";
import { reviewNeededReducer } from "../patientDataDetails/reviewNeeded/Reducer";
import { getPatientImportListReducer } from "../Patient/ImportData/Reducer";
import { getOrganizationPatientReducer } from "../PatientListByOrganization/Reducer";
import { getDoctorTeamTypeForCaseReducer } from "../Patient/AppointmentSelectDoctor/DoctorTeamTypeForCase/Reducer";
import { getDoctorListByCategoryReducer } from "../Patient/AppointmentSelectDoctor/DoctorListByCategory/Reducer";
import { getDoctorListForCaseReducer } from "../Patient/AppointmentSelectDoctor/DoctorListForCase/Reducer";
import { deleteDoctorForCaseReducer } from "../Patient/AppointmentSelectDoctor/DeleteDoctor/Reducer";
import { addDoctorForCaseReducer } from "../Patient/AppointmentSelectDoctor/AddDoctorForCase/Reducer";
import { editDoctorTeamTypeReducer } from "../Patient/AppointmentSelectDoctor/EditDoctorTeamType/Reducer";
import { govtIDProofReducer } from "../DemographicsDetails/govtIdProofList/Reducer";
import { dataListByObservationTypeReducer } from "../patientDashboardDataListByObservationType/Reducer";
import { getPatientDashboardLatestDataListReducer } from "../patientDashboardLatestDataList/Reducer";
import { jwtAuthTokenReducer } from "../jwtPatientLogIn/Reducer";
import { getPatientLoginDetailsReducer } from "../jwtPatientLogIn/patientLoginDetails/Reducer";
import { getDoctorTeamTypeListReducer } from "../Patient/AppointmentSelectDoctor/DoctorTeamTypeList/Reducer";
import { getPatientCaseNoteListReducer } from "../Notes/PatientCaseNoteList/Reducer";
import { updateNoteReducer } from "../Notes/updateNoteStatus/Reducer";
import { AddNoteReducer } from "../Notes/addNotes/Reducer";
import { getPatientNoteListReducer } from "../Notes/PatientNotesList/Reducer";
import {
  NotesForDrReducer,
  addDrNotes,
  addNotesForDrReducer,
  changeDrNoteStatus,
  changeNoteStatusForDrReducer,
} from "../Notes/Reducer";
import { connectOpenEpicReducer } from "redux/Open_Epic/ConnectEndPoint/Reducer";
import { appLoaderReducer } from "redux/appLoader/Reducer";
import { sleepScheduleObservationTypeReducer } from "redux/SleepAppApi/SleepScheduleObservationTypeApi/Reducer";
import { sleepQualityObservationTypeReducer } from "redux/SleepAppApi/SleepQualityObservationTypeApi/Reducer";
import { sleepScoreObservationTypeReducer } from "redux/SleepAppApi/SleepScoreObservationTypeApi/Reducer";
import { sleepTimeObservationTypeReducer } from "redux/SleepAppApi/SleepTimeObservationTypeApi/Reducer";
import { dataListByObservationTypeMappingListReducer } from "redux/PatientDashboardUiComponentObservationTypeMappingList/Reducer";
import { getObservationTypeReducer } from "redux/GetObservationTypeList/Reducer";
import { patientDetailsTypeReducer } from "redux/GetPatientDetailsTypeOfSession/PatientDetailsTypeList/Reducer";

const Reducer = combineReducers({
  emailOtpGeneration: emailOtpgenerationReducer,
  emailOtpSignup: emailOtpSignupReducer,
  emailOtpAuth: emailOtpAuthReducer,
  apiAuthToken: jwtAuthTokenReducer,
  forgotPassword: forgotPasswordReducer,
  doctorValue: getDoctorReducer,
  patientValue: getPatientReducer,
  sharedPatientValue: getSharedPatientReducer,
  organizationListValue: getOrganizationListReducer,
  organizationPatientListValue: getOrganizationPatientReducer,
  caseValue: getCaseReducer,
  questionValue: getQuestionReducer,
  ccValue: getCCReducer,
  ccAddVlaue: addCCReducer,
  deleteDocumentValue: deleteDocumentReducer,
  deletePatientValues: deletePatientReducer,
  taskValue: getTaskReducer,
  AppoinmentListValue: getOwnerAppoinmentListReducer,
  CaseAppoinmentListValue: getCaseAppoinmentListReducer,
  patientDemographicsRes: getPatientDemographicsDetailsReducer,
  Steps: getSteps,
  deleteCaseValue: deleteCaseReducer,
  sharedPatientDetailsRes: getSharedPatientDetailsReducer,
  healthcampListValue: getHealthcampListReducer,
  healthcampPatientListValue: getHealthcampPatientReducer,
  logOutRes: logOutReducer,
  getPatientDataDetailsRes: getPatientDataDetailsListReducer,
  patientDataPointList: getPatientDataPointListReducer,
  gethealthRecordvalue: getHealthRecordReducer,
  imageUploading: imageUploadReducer,
  addPointDataDetailsRes: addPointDataDetailsReducer,
  updatePatientDataDetailsRes: updatePatientDataDetailsReducer,
  showHistory: simpleHistoryReducer,
  addFavoritePatientValue: addFavoritePatientReducer,
  extractObservationByPatientIdValue: extractObservationByPatientIdReducer,
  getDemographicsDetailsByPtId: getDemographicsDetailsByPtIdReducer,
  extractObservationByDetailsIdValue: extractObservationByDetailsIdReducer,
  getExtractObservationValue: GetExtractObservationByPatientIdReducer,
  getPatientDetailsById: getPatientDetailsByIdReducer,
  getExtractObservationByDetailsValue: GetExtractObservationByDetailsIdReducer,
  bookAppointmentRequest: requestBookAppointmentReducer,
  checkPaymentStatusById: checkPaymentStatusByIdReducer,
  getPaymentListByOwner: getPaymentListByOwnerReducer,
  getDoctorDetailsById: getDoctorDetailsReducer,
  addNeedHelp: needHelpReducer,
  dropdown: dropdownReducer,
  getUserDetailsList: getUserDetailsListReducer,
  resetPassword: resetPasswordReducer,
  setEmailPhone: setEmailPhoneReducer,
  addPatientImportConfig: addPatientImportConfigReducer,
  getPatientImportConfigList: getPatientImportConfigListReducer,
  getPatientImportDataList: getPatientImportListReducer,
  editImportConfig: editPatientImportConfigReducer,
  getInsuranceDetails: getInsuranceDetailsReducer,
  addPatientInsurance: addPatientInsuranceReducer,
  categoryList: categoryListReducer,
  speciaList: speciaListReducer,
  doctorList: doctorListReducer,
  addDemographics: addDemographicsReducer,
  getPatientRelationshipList: getRelationshipListReducer,
  getPatientBloodGroupList: getBloodGroupListReducer,
  getPatientGenderList: getGenderListReducer,
  getPatientLanguageList: getLanguageListReducer,
  getPatientMaritalList: getMaritalListListReducer,
  getPatientSexualOrientation: getSexualOrientationListReducer,
  updateDemographicsDetails: updateDemographicsDetailsByPatientIdReducer,
  getAppointmentReqList: getAppointmentReqListReducer,
  createAppointmentReq: createAppointmentReqReducer,
  getAppointmentPaymentList: getAppointmentPaymentListReducer,
  getExistingAppointmentList: existingAppointmentListReducer,
  getSelectedDoctorTeamType: getSelectedDoctorTeamTypeReducer,
  getAppointmentSelectDoctorList: getAppointmentSelectedDoctorListReducer,
  deleteDoctor: deleteDoctorReducer,
  getDoctorCategoryList: getDoctorCategoryListReducer,
  getPatientDropdownList: patientDropdownListReducer,
  meetingList: getInstantMeetingReducer,
  questionList: getQuestionListForPatientReducer,
  consultationTypeValue: consultationTypeListReducer,
  getPatientCaseDetails: getPatientCaseDetailsReducer,
  opinionTypeValue: getOpinionTypeListReducer,
  secondOpinionValue: secondOpinionListReducer,
  addPatientCase: addPatientCaseReducer,
  responseTimeValue: responseTimeReducer,
  getPatientTeamRes: getPatientTeamReducer,
  addPatientTeamRes: addPatientTeamReducer,
  addHealthcampPatientData: addHealthcampPatientReducer,
  appointmentConfirmValue: appointmentConfirmReducer,
  appointmentPaymentListValue: appointmentPaymentListReducer,
  appointmentListValue: appointmentListReducer,
  appointmentPaymentStatusValue: appointmentPaymentStatusReducer,
  addRequestAppointment: addrequestAppointment,
  getrequestAppointmentDetails: requestAppointmentDetails,
  opinion: getOpinion,
  deleteOpinion: deleteOpinion,
  reviewNeeded: reviewNeededReducer,
  getDoctorTeamTypeForCaseRes: getDoctorTeamTypeForCaseReducer,
  editDoctorTeamTypeRes: editDoctorTeamTypeReducer,
  getDoctorCategoryListRes: getDoctorCategoryListReducer,
  getDoctorListByCategoryRes: getDoctorListByCategoryReducer,
  getDoctorListForCaseRes: getDoctorListForCaseReducer,
  deleteDoctorForCaseRes: deleteDoctorForCaseReducer,
  addDoctorForCaseRes: addDoctorForCaseReducer,
  doctorTeamTypeListValue: getDoctorTeamTypeListReducer,
  govtIdProofList: govtIDProofReducer,
  dataListByObservationTypeRes: dataListByObservationTypeReducer,
  getPatientDashboardLatestDataListRes: getPatientDashboardLatestDataListReducer,
  getPatientLoginDetailsRes: getPatientLoginDetailsReducer,

  patientCaseNoteListRes: getPatientCaseNoteListReducer,
  updateNoteRes: updateNoteReducer,
  addNoteRes: AddNoteReducer,
  patientNoteListRes: getPatientNoteListReducer,
  addDrNote: addDrNotes,
  changeDrNotesStatusValue: changeNoteStatusForDrReducer,
  addNotesForDrValue: addNotesForDrReducer,
  notesForDrvalues: NotesForDrReducer,
  changeDrNoteStatusValue: changeDrNoteStatus,
  connectOpenEpicRes: connectOpenEpicReducer,
  appLoaderRes: appLoaderReducer,
  observationTypeMappingListValue: dataListByObservationTypeMappingListReducer,
  sleepScoreObservationTypeValue: sleepScoreObservationTypeReducer,
  sleepTimeObservationTypeValue: sleepTimeObservationTypeReducer,
  sleepScheduleTypeValue: sleepScheduleObservationTypeReducer,
  sleepQualityTypeValue: sleepQualityObservationTypeReducer,
  getObservationTypeValue: getObservationTypeReducer,
  patientDetailsType: patientDetailsTypeReducer,
});

export default Reducer;
