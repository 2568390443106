import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { useParams } from "react-router-dom";
import {
  Pagination,
  Typography,
  Box,
  Grid,
  Stack,
  Card,
  CardHeader,
  CardContent,
  Button,
} from "@mui/material";
// import dayjs from "dayjs";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
// import { getExtractObservationByPatientId } from "../../redux/getExtractObservationByPatientId/API";
// import { ObservationList } from "../../redux/getExtractObservationByPatientId/Model";
// import MWExceptionList from "../../components/MWExceptionList";
import MWPageTitle from "../../components/MWPageTitle";
import { NameGenerate } from "../../components/Utility";
import MWLoader from "../../components/MWLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
type Props = {
  toggleState: boolean | any;
};

export default function PatientEncounterList({ toggleState }: Props) {
  //   const dispatch = useDispatch();
  const loadingMarkup = <MWLoader />;
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [page, setPage] = React.useState(1);
  // api store call..
  const getExtractObservationValue = useSelector(
    (state: AppState) => state.getExtractObservationValue,
  );

  // Encounter list api call here ...
  //   useEffect(() => {
  //     dispatch(getExtractObservationByPatientId(patientid, page));
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [dispatch]);

  const caseHistoryPtName = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName,
  );
  const [pageTitle, setPageTitle] = React.useState("");
  const [pageListCount, setPageListCount] = React.useState("");
  console.log(setPageListCount);

  const [listCount, setListCount] = React.useState("0");
  console.log(setListCount);

  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      //   dispatch(getExtractObservationByPatientId(patientid, page));
      console.log("passedVal====", passedVal);
    }
  };

  useEffect(() => {
    setPageTitle(
      "Patient Encounter List of " + NameGenerate.changeName(patientid, caseHistoryPtName),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleState]);

  const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    // page change api call here..
    // dispatch(getExtractObservationByPatientId(patientid, value));
  };

  //   const [dataGridArray, setDataArray] = React.useState([] as any[]);
  //   useEffect(() => {
  //     getExtractObservationValue?.pageCount !== undefined
  //       ? setPageListCount(String(getExtractObservationValue?.pageCount))
  //       : setPageListCount("0");

  //     getExtractObservationValue?.getExtractObservationRes?.count !== undefined
  //       ? setListCount(String(getExtractObservationValue?.getExtractObservationRes?.count))
  //       : setListCount("0");

  //     if (getExtractObservationValue?.getExtractObservationRes?.observationList !== undefined) {
  //       let dataPointArr = getExtractObservationValue?.getExtractObservationRes?.observationList.map(
  //         (element: ObservationList) => ({
  //           id: element.id,
  //           value: element.value,
  //           startDate: dayjs(element.startDate).format("dddd, MMMM D, YYYY"),
  //           displayValue: element.patientObservationType.displayValue,
  //           modifiedDate: dayjs(element.modifiedDate).format("dddd, MMMM D, YYYY"),
  //         }),
  //       );
  //       setDataArray(dataPointArr);
  //     } else {
  //       setDataArray([] as any[]);
  //       setPageListCount(String(getExtractObservationValue?.pageCount));
  //     }
  //   }, [getExtractObservationValue]);

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "View",
      align: "left",
      flex: 0.5,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            console.log("VIEWDATA", params.row);
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: "patientDetailsId",
      headerName: "patient Details Id",
      type: "string",
      editable: true,
      sortable: false,
      disableColumnMenu: true,
      width: 90,
      align: "left",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      editable: true,
      sortable: false,
      width: 250,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "string",
      editable: true,
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      align: "left",
      flex: 1,
    },
  ];

  const rows = [
    {
      id: 1,
      image: "",
      patientDetailsId: 49353,
      description: "Height:159",
      startDate: "Sunday,Feb 25,2024",
    },
    {
      id: 2,
      image: "",
      patientDetailsId: 49354,
      description: "Weight:57",
      startDate: "Sunday,Feb 25,2024",
    },
  ];

  const patientEncounterListTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}
            pt={2}
          >
            <Typography sx={{ paddingTop: "5px" }}>Page: {page}</Typography>
            <Pagination
              color="primary"
              count={Number(pageListCount)}
              page={page}
              onChange={handlePageChange}
            />
          </Grid>
        }
      />
      <CardContent>
        {/* {dataGridArray && dataGridArray.length ? ( */}
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter
          hideFooterPagination
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          autoHeight
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 250 },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
        {/* ) : (
          <MWExceptionList />
        )} */}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {getExtractObservationValue.loading === true ? loadingMarkup : ""}
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} pt={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={pageTitle}
              enableCount={true}
              count={listCount}
              reload={true}
              reloadAction={reloadActionFromChild}
            />
          </Grid>
        </Grid>
        {patientEncounterListTable}
      </Stack>
    </Box>
  );
}
